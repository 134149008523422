.merchant-details-wrapper {
	.table-wrapper {
		margin-top: 5px;

		table, th, td {
			border: 1px solid #ccc;
		}

		table {
			width: 100%;
			border-collapse: collapse;
		}

		.document-link-wrapper {
			display: flex;
			flex-direction: column;

			tr {
				flex: 1;

				td {
					display: block;
					width: 100%;
				}
			}
		}

		.partners-table {
			td {
				padding: 5px;
			}
		}
	}

	.merchant-details {
		margin-top: 24px;
	}
}

.field-value {
	margin-left: 3px;

	&.dx-icon-check {
		color: green;
	}

	&.dx-icon-close {
		color: red;
	}
}
