.client-detail {
	overflow: auto;
	padding: 0 40px 40px 40px;
	background: #FFFFFF;
	margin-top: 40px;
}

.profile-detail-title {
	font-weight: 500;
	margin-bottom: 30px;

	h1 {
		font-size: 36px;
		margin: 0 0 10px;
	}

	h2 {
		font-size: 20px;
		margin: 0 0 10px;
	}
}

.add-comment-wrapper {
	text-align: center;

	#add-comment-btn {
		margin: 20px 10px 0 10px;
	}
}

.gap-button {
	margin: 10px 5px;
}

.download-input-wrapper {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;

	#fileInput, .download-button {
		margin-top: 20px;
	}
}
