.merchant-details-wrapper {
	.table-wrapper {
		margin-top: 5px;

		table, th, td {
			border: 1px solid #ccc;
		}

		table {
			width: 100%;
			border-collapse: collapse;
		}

		.document-link-wrapper {
			display: flex;
			flex-direction: column;

			tr {
				flex: 1;

				td {
					display: block;
					width: 100%;
				}
			}
		}

		.partners-table {
			td {
				padding: 5px;
			}
		}
	}

	.merchant-details {
		margin-top: 24px;
	}
}

.field-value {
	margin-left: 3px;

	&.dx-icon-check {
		color: green;
	}

	&.dx-icon-close {
		color: red;
	}
}

.Collapsible {
	display: flex;
	flex-direction: column;
	font-size: 20px;
	margin: 10px 0;

	.Collapsible__trigger {
		cursor: pointer;
		margin-bottom: 10px;

		&:before {
			font: 24px/1 DXIcons;
			content: '\f010';
		}

		&.is-open {
			&:before {
				content: '\f016';
			}
		}
	}
}

.verification-item {
	display: flex;
	align-items: center;

	.icon-wrapper {
		margin-right: 5px;
		width: 25px;
		height: 25px;

		a {
			display: inline-block;
			height: 100%;

			img {
				height: 100%;
			}
		}
	}

	.dx-textbox {
		flex: 1;
	}
}