.restriction-field-wrap {
	.restriction-fieldset {
		padding-bottom: 40px;
	}

	.restr {
		.dx-button{
			margin-right: 10px;
		}
	}
}