.accordion-item {
	margin-bottom: 15px;

	.accordion-form {
		margin-top: 5px;
		padding-top: 20px;
		border-top: 1px solid #e0e0e0;
	}

	.accordion-header {
		display: flex;
		align-items: center;
		cursor: pointer;
	}
}

#profile-detail-form-wrapper {
	.overlay-form {
		background: red;
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 1;
	}
}
