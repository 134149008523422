$base-accent: #009688;
$base-font-family: "Roboto", "RobotoFallback", "Noto Kufi Arabic", "Helvetica", "Arial", sans-serif;
$base-text-color: #000000de;
$base-label-color: #000000de;
$base-icon-color: #0000008a;
$base-spin-icon-color: #0000008a;
$base-bg: #ffffff;
$base-border-color: #e0e0e0;
$base-success: #8bc34a;
$base-warning: #ffc107;
$base-danger: #f44336;
$base-hover-color: #000000de;
$base-hover-bg: #0000000a;
$base-focus-color: #ffffff;
$base-focus-bg: #0000000a;
$base-link-color: #009688;
$base-border-radius: 4px;
$button-normal-color: #000000de;
$button-normal-bg: #ffffff;
$button-normal-hover-bg: #ebebeb;
$button-normal-focused-bg: #ebebeb;
$button-normal-active-bg: #b3b3b3;
$button-default-color: #ffffff;
$button-default-bg: #009688;
$button-default-hover-bg: #006b61;
$button-default-focused-bg: #006b61;
$button-default-active-bg: #00332e;
$button-danger-color: #ffffff;
$button-danger-bg: #f44336;
$button-danger-hover-bg: #f11d0d;
$button-danger-focused-bg: #f11d0d;
$button-danger-active-bg: #bc160a;
$button-success-color: #ffffff;
$button-success-bg: #8bc34a;
$button-success-hover-bg: #75a938;
$button-success-focused-bg: #75a938;
$button-success-active-bg: #587f2a;
$list-group-color: #000000de;
$list-border-color: #e0e0e0;
$list-group-header-bg: transparent;
$list-normal-color: #000000de;
$list-expanded-group-color: #009688;
$list-item-hover-bg: #0000000a;
$list-item-selected-bg: #0000001f;
$list-item-active-bg: #cccccc;
$fieldset-field-label-color: #757575de;
$button-group-normal-selected-color: #000000de;
$button-group-normal-selected-bg: #0000002e;
$button-group-default-selected-color: #009688;
$button-group-default-selected-bg: #00968838;
$button-group-danger-selected-color: #f44336;
$button-group-danger-selected-bg: #f4433638;
$button-group-success-selected-color: #8bc34a;
$button-group-success-selected-bg: #8bc34a38;
$scrollable-scroll-bg: #bfbfbfb3;
$switch-bg: #9e9e9e;
$switch-handle-off-bg: #fafafa;
$switch-on-bg: #00968880;
$switch-handle-on-bg: #009688;
$tabs-tab-color: #0000008a;
$tabs-tab-selected-color: #009688;
$tabs-tab-disabled-color: #00000021;
$tabs-selected-tab-bottom-border-color: #009688;
$tabs-disabled-tab-bottom-border-color: #00000021;
$tabs-tab-bg: #ffffff;
$tabs-tab-icon-selected-color: #009688;
$tabs-tab-icon-disabled-color: #00000021;
$tabs-hovered-tab-bg-color: #00968814;
$tabs-focused-tab-bg-color: #0096881f;
$tabs-active-tab-bg-color: #00968829;
$badge-color: #ffffff;
$badge-bg: #009688;
$overlay-content-bg: #ffffff;
$overlay-shader-bg: #00000052;
$textbox-search-icon-color: #0000008a;
$texteditor-color: #000000de;
$texteditor-placeholder-color: #949494de;
$texteditor-bg: #0000000a;
$texteditor-focused-bg: #0000000a;
$texteditor-border-color: #0000006b;
$texteditor-hover-border-color: #000000de;
$texteditor-focused-border-color: #009688;
$texteditor-hover-bg: #00000012;
$dropdowneditor-icon-active-color: #0000008a;
$calendar-header-color: #000000de;
$calendar-cell-other-color: #00000061;
$calendar-cell-selected-bg: #009688;
$load-indicator-segment-bg-color: #009688;
$treeview-focused-bg: #0000000a;
$menu-color: #757575de;
$menu-item-selected-bg: #e0e0e0de;
$selectbox-list-bg: #ffffff;
$toolbar-bg: #ffffff;
$popup-title-bg: transparent;
$tileview-color: #000000de;
$toast-color: #ffffffde;
$toast-info-bg: #000000de;
$toast-warning-bg: #ffc107;
$toast-error-bg: #f44336;
$toast-success-bg: #8bc34a;
$progressbar-bg: #0096884d;
$progressbar-range-bg: #009688;
$progressbar-label-color: #009688;
$tooltip-color: #ffffff;
$tooltip-bg: #616161;
$material-slider-bar-bg: #0096883d;
$material-slider-bg: #009688;
$material-slider-tooltip-color: #ffffff;
$gallery-indicator-bg: #ffffff;
$gallery-indicator-item-selected-bg: #009688;
$gallery-indicator-focused-bg: #009688;
$gallery-navbutton-hover-color: #009688;
$gallery-navbutton-active-color: #009688;
$loadpanel-content-bg: #ffffff;
$tagbox-tag-color: #00000099;
$tagbox-tag-bg: #e0e0e0;
$tagbox-tag-active-color: #000000de;
$tagbox-tag-button-remove-bg: #0000005c;
$accordion-title-color: #000000de;
$accordion-item-border-color: #e0e0e0;
$accordion-icon-active-color: #000000de;
$colorbox-overlay-bg: #ffffff;
$datagrid-base-background-color: #ffffff;
$datagrid-columnchooser-item-color: #0000008a;
$datagrid-columnchooser-font-weight: 500;
$datagrid-drag-header-border-color: #00968880;
$datagrid-selection-bg: #0096880a;
$datagrid-row-selected-border-color: #e0e0e0;
$datagrid-row-selected-color: #000000de;
$datagrid-row-focused-color: #ffffff;
$datagrid-row-focused-bg: #33aba0;
$datagrid-menu-icon-color: #5656568a;
$datagrid-cell-modified-border-color: #8bc34a52;
$datagrid-row-invalid-faded-border-color: #f4433652;
$datagrid-group-row-color: #0000008a;
$datagrid-group-row-bg: #ffffff;
$datagrid-search-color: #ffffff;
$datagrid-search-bg: #009688;
$datagrid-row-error-color: #ffffff;
$datagrid-row-error-bg: #f88e86;
$datagrid-focused-border-color: #33aba0;
$datagrid-editor-bg: transparent;
$datagrid-row-alternation-bg: #f5f5f5;
$datagrid-summary-color: #000000b3;
$pager-page-selected-color: #ffffff;
$pager-page-selected-bg: #009688;
$pivotgrid-area-color: #0000008a;
$pivotgrid-totalcolor: #dddddd33;
$pivotgrid-grandtotalcolor: #0000000a;
$pivotgrid-field-area-text-color: #0000004d;
$fileuploader-filename-color: #000000de;
$fileuploader-falename-status-color: #666666de;
$fileuploader-border-color: #e0e0e0;
$scheduler-base-border-color: #e0e0e0;
$scheduler-accent-border-color: #e0e0e0;
$scheduler-appointment-text-color: #ffffff;
$scheduler-appointment-base-color: #009688;
$scheduler-appointment-start-color: #0000004d;
$scheduler-workspace-focused-cell-color: #0096881f;
$scheduler-first-month-border-color: #e0e0e0;
$scheduler-current-time-cell-color: #009688;
$form-field-item-color: #00000099;
$form-group-border-color: #e0e0e0;
$filterbuilder-text-color: #000000de;
$filterbuilder-text-focus-color: #ffffff;
$filterbuilder-plus-icon-color: #8bc34ab3;
$filterbuilder-remove-icon-color: #f44336b3;
$filterbuilder-group-operation-color: #f443364d;
$filterbuilder-item-field-color: #0096884d;
$filterbuilder-item-operator-color: #8bc34a4d;
$filterbuilder-item-value-color: #e0e0e080;
$drawer-shader-background-color: #00000080;
