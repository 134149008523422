#root {
	display: flex;
	flex-direction: column;
	height: 100%;

	.admin-popup-wrapper {
		.dx-popup-wrapper > .dx-overlay-content {
			border-radius: 10px;
		}
	}

	.dx-popup-content {
		position: relative;

		.admin-popup-close {
			position: absolute;
			top: 10px;
			right: 10px;
			cursor: pointer;
			width: 20px;
			height: 20px;
			line-height: 20px;

			i {
				font-size: 20px;
				color: #d7d7d7;
			}
		}

		.admin-popup-content {
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0 20px 33px 20px;

			.admin-popup-logo {
				width: 180px;
				margin: 30px 0 15px 0;
			}

			.admin-popup-head {
				text-align: center;
				margin-bottom: 10px;

				.admin-popup-title {
					font-size: 18px;
				}
			}

			.admin-popup-component {
				height: 100%;
				width: 100%;

				.password-recovery-component {
					display: flex;
					flex-direction: column;
					align-items: center;

					.component-subtitle {
						margin-bottom: 20px;
						font-size: 12px;
						color: #8e8e93;
					}
				}
			}
		}
	}
}
