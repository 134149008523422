@import '../../themes/generated/variables.base.scss';

.login-wrapper {
	background: $base-bg;
	padding: 50px;

	.login-header {
		text-align: center;
		margin-bottom: 40px;

		.title {
			color: $base-accent;
			font-weight: bold;
			font-size: 30px;
			margin: 0;
		}
	}

	.success-button {
		margin-top: 20px;
		text-align: center;
	}
}