.action-wrapper {
	margin-top: 30px;

	.clients-popup-field{
		margin-bottom: 15px;
	}
	.action-buttons {
		display: flex;
		justify-content: center;
		.action-button {
			margin: 20px 10px 0 10px;
		}
	}
}