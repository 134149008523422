.side-nav-outer-toolbar {
	flex-direction: column;
	display: flex;
	height: 100%;
	width: 100%;
}

.layout-header {
	z-index: 1501;
}

.layout-body {
	flex: 1 1;
	//min-height: 0;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.content {
	flex: 1 1;
	min-height: 0;
	display: flex;
	flex-direction: column;
}

.with-footer {
	.dx-scrollview-content, .content {
		height: 100%;
	}
}
