.scroll-view {
	width: 100%;
	height: 100%;

	.info-wrapper {
		.info-header {
			font-weight: bold;
		}

		.dx-field {
			margin: 0 0 15px;
		}
	}
}
