@import "./themes/generated/variables.base.scss";
@import "~@mdi/font/css/materialdesignicons.css";

.app {
  background-color: darken($base-bg, 5.00);
  display: flex;
  height: 100%;
  width: 100%;
}

.under-construction {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30em;
}

.clients-wrapper, .checkout-wrapper {
  flex: 1 1;
  min-height: 0;
  display: flex;
  flex-direction: column;

  .admin-navigation-menu {
    flex: 0 0 auto;

  }

  .active-component-wrapper {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: white;

    position: relative;
  }
}

.admin-navigation-menu {
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: $base-border-color;
  }

  a {
    display: flex;
    height: 100%;
    align-items: center;
    text-decoration: none;
    margin: 0 10px;
    position: relative;
  }

  a.selected:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: $base-link-color;
    z-index: 2;
  }
}

.special-payment-type-id {
  color: #F24236;
}

.page-component-wrapper {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
}

.dx-toast-content {
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
}

.fields-loader {
  text-align: center;
  margin-top: 20px;
}

.column-with-filter {
  color: $base-accent;
}

/* weird two buttons when clear filters tagboxes*/
.dx-datagrid-filter-panel-clear-filter {
  visibility: hidden;
}

.dx-datagrid-filter-panel-clear-filter:nth-last-of-type(1) {
  visibility: visible;
}

.details-buttons > div {
  margin: 0 10px 20px 0;
}

.popover-buttons {
  .dx-button {
    margin-right: 10px;
  }
}

.field-label, .field-info {
  color: rgba(117, 117, 117, .87);
  font-size: 12px;
  cursor: default;
  text-align: left;
  padding-right: 15px;
}

.field-info {
  margin-bottom: 15px;
}

.dx-datagrid {
  .dx-row > td {
    font-size: 12px !important;
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
}

.dx-overlay-wrapper.dx-dropdowneditor-overlay {
  &.dx-popup-wrapper.dx-dropdownlist-popup-wrapper.dx-selectbox-popup-wrapper {
    &.dx-tagbox-popup-wrapper {
      .dx-overlay-content {
        .dx-popup-content {
          .dx-scrollable {
            .dx-scrollable-wrapper {
              .dx-scrollable-container {
                .dx-scrollable-content {
                  .dx-scrollview-content {
                    .dx-list-select-all {
                      font-size: 12px;
                      padding: 13px 0;
                      margin-bottom: 0;

                      .dx-list-select-all-label {
                        margin-top: 0;
                        padding: 0 16px;
                      }
                    }

                    .dx-item.dx-list-item {
                      .dx-list-item-before-bag.dx-list-select-checkbox-container {
                        width: 50px;
                      }

                      .dx-list-item-content {
                        font-size: 12px;
                        padding: 13px 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.dx-fileuploader-wrapper {
  max-width: 110px;
  padding: 0;

  .dx-fileuploader-input-wrapper {
    width: 110px;
    border: none;
  }

  .dx-fileuploader-files-container {
    display: none;
  }
}

.dx-editor-cell {
  .dx-texteditor {
    .dx-texteditor-input {
      font-size: 12px;
    }
  }
}

.dx-dropdowneditor-overlay {
  .dx-list-item-content {
    font-size: 12px;
  }
}

.merchant-details-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.tabs-wrapper-vertical {
  display: flex;
  gap: 24px;

  .tabs-container {
    border-right: 1px solid $base-border-color;
  }

  .tab-content {
    width: 100%;
  }
}

.create-legal-client-wrapper {
  width: 100%;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .create-legal-client-select {
    width: 100%;
  }

  .action-buttons {
    display: flex;
    gap: 20px;
  }
}

#client-detail-tabs {
  position: sticky;
  top: 0;
  left: 0;
}

.page-component-wrapper-custom {
  width: 100%;
  height: 100%;
  background-color: #f2f5fe;
  padding: 0 20px 0 20px;

  .grid-wrapper {
    overflow: hidden;
    height: 100%;
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;

    & > div {
      height: 100%;
    }

    .dx-header-row {
      td:first-child {
        border-top-left-radius: 23px;
      }
    }
  }

  .dx-widget {
    font-family: Montserrat, sans-serif;
  }

  .dx-datagrid {
    color: #333 !important;
  }

  .dx-datagrid-headers.dx-datagrid-nowrap.dx-bordered-top-view {
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
  }

  .dx-datagrid-header-panel {
    border: none;
  }

  .dx-datagrid-headers .dx-header-row .dx-datagrid-action {
    font-weight: 700;
    color: #333;
  }

  .round-toolbar-button {
    border-radius: 18px;

    .dx-button-content {
      .dx-button-text {
        text-transform: none;
      }
    }
  }

  .dx-fileuploader-input-wrapper {
    padding: 0;
    margin: 0 auto;

    .dx-fileuploader-button {
      margin-left: 15px;
      border-radius: 18px;
      background-color: transparent;
      border: 1px solid rgba(0, 0, 0, .24);
      box-shadow: none;

      &:hover {
        background-color: rgba(0, 0, 0, .08);
      }
    }
  }
}


.dx-datagrid-headers {
  color: #333;

  .dx-datagrid-table .dx-row > td {
    font-weight: 700 !important;
  }
}

.client-detail-top {
  background-color: white;
  padding: 40px 0 0 40px;
}

.profile-detail-wrapper {
  padding: 40px;
  height: 100%;

  .client-detail-scroll {
    height: calc(100% - 120px);

    .client-detail-inner {
      height: 100%;

      .client-create-tab-wrapper {
        height: 100%;

        .accordion-items-wrapper {
          height: 100%;

          .tabs-wrapper-vertical {
            overflow: auto;
            height: inherit;

            .tabs-container {
              position: sticky;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
}

.tab-item {
  display: none;

  &.visible-tab {
    display: block;
  }
}

.button-group-wrapper {
  .dx-item-selected {
    background: transparent;
  }

  .dx-button:hover {
    background-color: rgba(0,0,0,.08);
  }
}

#ctx-menu {
  position: absolute;
  width: auto;
  height: auto;
  background: white;
  opacity: 0;
  z-index: -1;
  border-radius: 12px;
  box-shadow: 0 4px 27px 0 rgba(0,0,128,.1);
  padding: 12px 0;

  &.visible {
    z-index: 11;
    opacity: 1;
  }

  .column-operation-button {
    cursor: pointer;
    display: flex;
    padding: 4px 10px;
    gap: 5px;

    &.invisible {
      display: none;
    }

    &:hover {
      background-color: rgba(0,0,0,.08);
    }
  }
}

#ctx-wrapper {
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  z-index: 10;

  &.visible {
    display: block;
  }
}

.text-area-value {
  margin: 0;
}

.field-value-flex {
  display: flex;
  gap: 15px;
  align-items: center;
}

.custom-action-button {
  border-radius: 18px;
}