#grid-acc-statement {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	min-height: 0;
}


.status-column {
	position: relative;
	padding-bottom: 5px;

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
	}

	&.green-status:after {
		background: green;
	}

	&.red-status:after {
		background: red;
	}

	&.black-status:after {
		background: black;
	}


	&.blue-status:after {
		background: blue;
	}
}

.in-order-currency {
	display: flex;
	flex-direction: column;
	height: 80px;
	justify-content: flex-start;

	&_right {
		align-items: flex-end;
	}

	p {
		margin: 0;
	}
}