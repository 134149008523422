.scroll-view {
	width: 100%;
	height: 100%;

	.action-wrapper {
		margin-top: 30px;

		.payment-details {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-bottom: 15px;

			p {
				color: rgba(117,117,117,.87);
				font-size: 12px;
				cursor: default;
				padding-right: 15px;
				margin: 0;
			}
		}

		&.accept-decline-replay {
			margin-top: 5px;

			.field-wrapper {
				margin-bottom: 20px;
			}
		}

		.action-buttons {
			display: flex;
			justify-content: center;

			.action-button {
				margin: 20px 10px 10px 10px;
			}
		}
	}
}
